import React from 'react';
import LayoutIT from '../components/layoutIT';
import SEO from '../components/seo';
import AboutIT from '../containers/artistesIT';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <LayoutIT>
      <SEO
        title="Gli artisti, Galleria Sifrein Parigi"
        description="Presentazione degli artisti in esposizione alla galleria Sifrein di Parigi"
      />

      <AboutIT />
    </LayoutIT>
  );
};

export default AboutPage;
